
function mobiletoggleClass(addRemoveClass, className) {
    console.log('kjhkjhk')
    const el = document.querySelector('#offcanvas-menu');
    if (addRemoveClass === 'addClass') {
        el.classList.add(className);
    } else {
        el.classList.remove(className);
    }
}

// const headerMobileMenuToggle = document.querySelector('.header-mobile-menu-toggle .toggle');
// headerMobileMenuToggle.onclick = mobiletoggleClass('addClass', 'show-mobile-menu');
// headerMobileMenuToggle.addEventListener('click', mobiletoggleClass('addClass', 'show-mobile-menu'));

// const mobileMenuOverlay = document.querySelector('.mobile-menu-overlay');
// mobileMenuOverlay.onclick = mobiletoggleClass('removeClass', 'show-mobile-menu');
// mobileMenuOverlay.addEventListener('click', mobiletoggleClass('removeClass', 'show-mobile-menu'));

// const mobileCloseBtn = document.querySelector('.mobile-close-btn');
// mobileCloseBtn.addEventListener('click', mobiletoggleClass('removeClass', 'show-mobile-menu'));

const offCanvasNav = document.querySelector('#offcanvas-navigation');
const offCanvasNavSubMenu = offCanvasNav.querySelectorAll('.sub-menu');
const anchorLinks = offCanvasNav.querySelectorAll('a');

for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
    offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
}

const menuExpand = offCanvasNav.querySelectorAll('.menu-expand');
const numMenuExpand = menuExpand.length;

for (let i = 0; i < numMenuExpand; i++) {
    menuExpand[i].addEventListener("click", (e) => { sideMenuExpand(e) });
}

for (let i = 0; i < anchorLinks.length; i++) {
    anchorLinks[i].addEventListener("click", () => { closeMobileMenu() });
}

const sideMenuExpand = (e) => {
    e.currentTarget.parentElement.classList.toggle('active');
}
const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector('#offcanvas-mobile-menu');
    offcanvasMobileMenu?.classList.remove('active');
}
